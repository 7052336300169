import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AppLayout } from '../components/Layouts'
import { supabase } from '../utils/supabaseCilent'

function Form() {
  const [imagefile, setImageFile] = useState([])
  const [name, setName] = useState('')
  const [sex, setSex] = useState('')
  const [day, setDay] = useState(null)
  const [month, setMonth] = useState(null)
  const [year, setYear] = useState(null)
  const [idNo, setIdNo] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [cvfile, setCvFile] = useState([])
  const [uploadProfileAllowed, setUploadProfileAllowed] = useState(true)
  const [uploadResumeAllowed, setUploadResumeAllowed] = useState(true)
  const { id } = useParams()

  let navigate = useNavigate()

  const MAX_UPLOAD_PROFILE_PHOTO = 5 * 1024 * 1024
  const MAX_UPLOAD_RESUME = 25 * 1024 * 1024

  useEffect(() => {
    if (imagefile.length && imagefile[0].size > MAX_UPLOAD_PROFILE_PHOTO) {
      setUploadProfileAllowed(false)
    } else {
      setUploadProfileAllowed(true)
    }
  }, [MAX_UPLOAD_PROFILE_PHOTO, imagefile])

  useEffect(() => {
    if (cvfile.length && cvfile[0].size > MAX_UPLOAD_RESUME) {
      setUploadResumeAllowed(false)
    } else {
      setUploadResumeAllowed(true)
    }
  }, [MAX_UPLOAD_RESUME, cvfile])

  const YearLoop = () => {
    const elements = []
    for (let index = 2543; index >= 2483; index--) {
      elements.push(
        <option key={index} value={index}>
          {index}
        </option>
      )
    }
    return elements
  }

  const MonthLoop = () => {
    const elements = []
    for (let index = 1; index <= 12; index++) {
      elements.push(
        <option key={index} value={index}>
          {index}
        </option>
      )
    }
    return elements
  }

  const DayLoop = () => {
    const elements = []
    for (let index = 1; index <= 31; index++) {
      elements.push(
        <option key={index} value={index}>
          {index}
        </option>
      )
    }
    return elements
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const profilePhoto = imagefile[0]
    const resumePhoto = cvfile[0]
    let fileProfilePath = ''
    let fileResumePath = ''

    if (uploadProfileAllowed && uploadResumeAllowed) {
      if (resumePhoto) {
        const fileResume = resumePhoto
        const fileResumeExt = fileResume.name.split('.').pop()
        const fileResumeName = `${Math.random()}.${fileResumeExt}`
        fileResumePath = `resume-photo/${fileResumeName}`
        await supabase.storage
          .from('jobs')
          .upload(fileResumePath, resumePhoto, {
            cacheControl: '3600',
            upsert: false,
          })
      }
      if (profilePhoto) {
        const fileProfile = profilePhoto
        const fileProfileExt = fileProfile.name.split('.').pop()
        const fileProfileName = `${Math.random()}.${fileProfileExt}`
        fileProfilePath = `profiles-photo/${fileProfileName}`
        await supabase.storage
          .from('jobs')
          .upload(fileProfilePath, profilePhoto, {
            cacheControl: '3600',
            upsert: false,
          })
      }
      await supabase.from('application_form').insert({
        profile: fileProfilePath,
        fullname: name.trim(),
        sex: sex,
        date_day: day,
        date_month: month,
        date_year: year,
        id_card: idNo,
        email: email,
        phone: phone,
        address: address,
        resume: fileResumePath,
        job_id: id,
      })
      alert('Thank You!! \n\nYour submission has been received')
      navigate(`/jobs/${id}/`)
    }
  }

  const sizeFormatted = (value) => value / (1024 * 1024)

  return (
    <AppLayout>
      <div className="max-w-5xl mx-auto py-6 mt-4 px-6 lg:px-8">
        <div className=" bg-white text-black shadow rounded">
          <div className="text-2xl bg-gray-300 py-3 px-6 lg:px-8 rounded-t">
            ประวัติส่วนตัว
          </div>
          <form className="p-6 max-w-xl mx-auto" onSubmit={handleSubmit}>
            <div className="grid grid-cols-6 gap-4 pb-7">
              <div id="profile" className="col-span-6 py-2 mb-2">
                <p className="px-1 pb-2">แนบรูปภาพ</p>
                <input
                  required
                  className={`cursor-pointer hover:bg-gray-100 w-2/5 md:w-1/3 ${
                    !uploadProfileAllowed
                      ? 'border-2 border-red-400 p-2 w-full rounded'
                      : ''
                  }`}
                  type="file"
                  name="flie"
                  accept="image/*"
                  onChange={(e) => {
                    setImageFile(e.target.files)
                  }}
                ></input>
                <br />
                {!uploadProfileAllowed && (
                  <small className="text-red-500 font-semibold">
                    ขนาดไฟล์ต้องไม่เกิน{' '}
                    {sizeFormatted(MAX_UPLOAD_PROFILE_PHOTO)} MB
                  </small>
                )}
                <br />
                <small className="text-gray-500 font-semibold">
                  รองรับไฟล์ JPG,PNG,JEPG ขนาดไม่เกิน{' '}
                  {sizeFormatted(MAX_UPLOAD_PROFILE_PHOTO)} MB
                </small>
              </div>
              <div className="col-span-6 mt-2">
                <label>ชื่อ-นามสกุล</label>
                <span className="text-red-500"> *</span>
                <br />
                <input
                  name="fullname"
                  autoComplete="on"
                  type="text"
                  required
                  pattern="[a-zA-Zก-ุฯ-๙- ]{1,}"
                  title="กรอกตัวอักษรเท่านั้น"
                  className="my-2 w-full rounded bg-gray-100 pl-2"
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
              <div className="col-span-6 md:col-span-3">
                <label>เพศ</label>
                <span className="text-red-500"> *</span>
                <p>
                  <input
                    className="accent-red-500"
                    type="radio"
                    name="gender"
                    value="male"
                    required
                    onClick={(e) => setSex(e.target.value)}
                  ></input>
                  <label className="px-2">ชาย</label>
                  <input
                    className="accent-pink-500"
                    type="radio"
                    name="gender"
                    value="female"
                    onClick={(e) => setSex(e.target.value)}
                  ></input>
                  <label className="px-2">หญิง</label>
                </p>
              </div>
              <div className="col-span-6">
                <label>วันเกิด</label>
                <div className="flex flex-row space-x-2">
                  <select
                    className="bg-gray-100 rounded"
                    value={day || ''}
                    onChange={(e) => {
                      setDay(e.target.value)
                    }}
                  >
                    <option></option>
                    <DayLoop />
                  </select>
                  <select
                    className="bg-gray-100 rounded"
                    value={month || ''}
                    onChange={(e) => setMonth(e.target.value)}
                  >
                    <option></option>
                    <MonthLoop />
                  </select>
                  <select
                    className="bg-gray-100 rounded"
                    value={year || ''}
                    onChange={(e) => setYear(e.target.value)}
                  >
                    <option></option>
                    <YearLoop />
                  </select>
                </div>
              </div>
              <div className="col-span-6 w-full">
                <label>เลขบัตรประชาชน</label>
                <span className="text-red-500"> *</span>
                <br />
                <input
                  type="text"
                  required
                  pattern="[0-9]+"
                  title="กรอกตัวเลขเท่านั้น"
                  className="my-2 w-full px-2 rounded bg-gray-100"
                  maxLength="13"
                  minLength="13"
                  onChange={(e) => setIdNo(e.target.value)}
                ></input>
              </div>
              <div className="col-span-6 w-full">
                <label>อีเมล</label>
                <span className="text-red-500"> *</span>
                <br />
                <input
                  name="email"
                  autoComplete="on"
                  type="email"
                  required
                  className="my-2 w-full px-2 rounded bg-gray-100"
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </div>
              <div className="col-span-6 w-full">
                <label>เบอร์โทรศัพท์</label>
                <span className="text-red-500"> *</span>
                <br />
                <input
                  required
                  type="tel"
                  name="phone"
                  autoComplete="on"
                  pattern="[0-9]+"
                  title="กรอกตัวเลขเท่านั้น"
                  minLength="9"
                  maxLength="10"
                  className="my-2 w-full px-2 rounded bg-gray-100"
                  onChange={(e) => setPhone(e.target.value)}
                ></input>
              </div>
              <div className="col-span-6 w-full">
                <label>ที่อยู่</label>
                <span className="text-red-500"> *</span>
                <br />
                <textarea
                  required
                  minLength="10"
                  className=" w-full h-24 resize-none p-2 rounded bg-gray-100"
                  onChange={(e) => setAddress(e.target.value)}
                ></textarea>
              </div>
              <div id="resume" className="col-span-6 py-2 mb-2">
                <p className="px-1 pb-2">แนบ Resume</p>
                <input
                  required
                  className={`cursor-pointer hover:bg-gray-100 w-1/2 md:w-1/3 ${
                    !uploadResumeAllowed
                      ? 'border-2 border-red-400 p-2 w-full rounded'
                      : ''
                  }`}
                  type="file"
                  name="flie"
                  accept="application/zip,application/pdf"
                  onChange={(e) => {
                    setCvFile(e.target.files)
                  }}
                ></input>
                <br />
                {!uploadResumeAllowed && (
                  <small className="text-red-500 font-semibold">
                    ขนาดไฟล์ต้องไม่เกิน {sizeFormatted(MAX_UPLOAD_RESUME)} MB
                  </small>
                )}
                <br />
                <small className="text-gray-500 font-semibold">
                  รองรับไฟล์ ZIP,PDF ขนาดไม่เกิน{' '}
                  {sizeFormatted(MAX_UPLOAD_RESUME)} MB
                </small>
              </div>
            </div>
            <div className="pb-5">
              <button
                type="submit"
                className="bg-orange-500 flex justify-center rounded py-2 mx-auto text-white text-lg hover:bg-orange-600 w-full shadow max-w-xs"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </AppLayout>
  )
}

export default Form
