import React, { useEffect, useState } from 'react'
import { supabase } from '../../utils/supabaseCilent'

function ImageUpload({
  url,
  onUpload,
  pathURL,
  inputstyle,
  imgstyle,
  TitleAlt,
}) {
  const [imageUrl, setImageUrl] = useState(null)
  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    if (url) downloadImage(url)
  }, [url])

  const downloadImage = async (path) => {
    try {
      const { publicURL, error } = supabase.storage
        .from('jobs')
        .getPublicUrl(path)

      if (error) {
        throw error
      }

      setImageUrl(publicURL)
    } catch (error) {
      console.log('Error downloading image: ', error.message)
    }
  }

  const uploadImage = async (event) => {
    try {
      setUploading(true)

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error('You must select an image to upload.')
      }

      const file = event.target.files[0]
      const fileExt = file.name.split('.').pop()
      const fileName = `${Math.random()}.${fileExt}`
      const filePath = `${pathURL}${fileName}`

      let { error: uploadError } = await supabase.storage
        .from('jobs')
        .upload(filePath, file)

      if (uploadError) {
        throw uploadError
      }

      onUpload(filePath)
    } catch (error) {
      alert(error.message)
    } finally {
      setUploading(false)
    }
  }
  return (
    <div>
      {uploading ? (
        'Uploading...'
      ) : (
        <>
          <input
            className={`my-2 ${inputstyle}`}
            type="file"
            accept="image/*"
            onChange={uploadImage}
            disabled={uploading}
          />
        </>
      )}
      <img
        alt={`${TitleAlt}`}
        className={`scale-100 ${imgstyle}`}
        src={imageUrl}
      />
    </div>
  )
}

export default ImageUpload
