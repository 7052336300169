import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Companydetail from './page/companies/show'
import Companylist from './page/companies/index'
import Form from './page/Form'
import Home from './page/Home'
import Joblist from './page/jobs/index'
import Jobdetail from './page/jobs/show'

import AllJob from './page/Hr/Jobs/AllJobs'
import JobEdit from './page/Hr/Jobs/JobEdit'
import AllCompanies from './page/Hr/Companies/AllCompanies'
import CompanyEdit from './page/Hr/Companies/CompanyEdit'
import CompanyCreate from './page/Hr/Companies/CompanyCreate'
import JobCreate from './page/Hr/Jobs/JobCreate'
import AllApplicationForm from './page/Hr/ApplicationForm/AllApplicationForm'
import ApplicationFormDetail from './page/Hr/ApplicationForm/ApplicationFormDetail'
import Dashboard from './page/Hr/Dashboard/Dashboard'
import LogIn from './page/auth/LogIn'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="login" element={<LogIn />} />
        <Route path="companies" element={<Companylist />} />
        <Route path="companies/:id" element={<Companydetail />} />
        <Route path="jobs" element={<Joblist />}></Route>
        <Route path="jobs/:id" element={<Jobdetail />} />
        <Route path="jobs/:id/form" element={<Form />} />
        <Route path="hr" element={<Dashboard />} />
        <Route path="hr/company" element={<AllCompanies />} />
        <Route path="hr/company/create" element={<CompanyCreate />} />
        <Route path="hr/company/edit/:id" element={<CompanyEdit />} />
        <Route path="hr/form" element={<AllApplicationForm />} />
        <Route path="hr/form/detail/:id" element={<ApplicationFormDetail />} />
        <Route path="hr/job" element={<AllJob />} />
        <Route path="hr/job/creat" element={<JobCreate />} />
        <Route path="hr/job/edit/:id" element={<JobEdit />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
