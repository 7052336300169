import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import parse from 'html-react-parser'
import { AppLayout } from '../../components/Layouts'
import JobCard from '../../components/JobCard'
import { supabase } from '../../utils/supabaseCilent'

const salaryFormatted = (min, max) => {
  if (min && max) return `${min} - ${max}`
  if (!min && !max) return 'ตามตกลง'
  if (min && !max) return `start ${min}`
  if (!min && max) return `max ${max}`
}

const Jobdetail = () => {
  const [job, setJob] = useState({})
  const [companyLogo, setCompanyLogo] = useState()
  const [jobs, setJobs] = useState([])
  const { id } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await supabase
        .from('jobs')
        .select(`*, company:companies ( * )`)
        .eq('id', id)
        .single()

      setJob(data)
    }

    fetchData()
  }, [id])

  useEffect(() => {
    const fetchData = async () => {
      const { data: jobs } = await supabase
        .from('jobs')
        .select(`*, company:companies ( logo )`)
        .eq('company_id', job.company_id)
        .order('published_at', { ascending: false })
        .lte('published_at', new Date().toISOString())
        .limit(5)

      setJobs(jobs)
    }

    if (job.company_id) {
      fetchData()
    }
  }, [job.company_id])

  useEffect(() => {
    const getFullURL = (imagePath) => {
      const { publicURL } = supabase.storage
        .from('jobs')
        .getPublicUrl(imagePath)

      return publicURL
    }

    if (job.company?.logo) {
      setCompanyLogo(getFullURL(job.company.logo))
    } else {
      setCompanyLogo('https://dummyimage.com/200x200/cccccc/ffffff')
    }
  }, [job.company])

  const jobTimeZone = (published_at) => {
    if (job.published_at) {
      const jobTime = new Date(published_at)

      return jobTime
        .toLocaleString('en-GB', { timeZone: 'UTC' })
        .substring(0, 10)
    } else {
      return ''
    }
  }

  return (
    <AppLayout>
      <div className="max-w-5xl mx-auto py-6 lg:mt-4 px-6 lg:px-8">
        <div>
          {job && (
            <div className="block p-6 bg-white rounded shadow">
              <div className="grid grid-cols-12 gap-5 md:gap-2">
                <div className="hidden sm:block col-span-5 sm:col-span-2 md:col-span-2 p-2">
                  <img
                    className="w-full scale-90 md:scale-100"
                    src={companyLogo}
                    alt={`${job.name}`}
                  />
                </div>
                <div className="pt-2 col-span-12 sm:col-span-8 overflow-hidden font-semibold text-2xl">
                  <b>{job.name} </b>
                  <div className=" mb-2 text-xl text-gray-400">
                    <b>{job.level}</b>
                    <br />
                    <i className=" text-primary-500">
                      {salaryFormatted(job.salary_min, job.salary_max)}
                    </i>
                    <br />
                    <b className="text-base">{job.location}</b>
                  </div>
                </div>
              </div>
              <div className="pt-3 grid grid-cols-6 gap-4">
                <div className="job-description col-span-6 md:col-span-4">
                  {parse(job.description || '')}
                </div>

                <div className="col-span-6 md:col-span-2 text-left text-black">
                  <div className="border-t-2 visible md:invisible"></div>
                  <div className=" md:border-l-2 border-gray-300  md:px-5 pt-2 md:pt-0">
                    <p className="text-base font-semibold text-gray-400 pb-5">
                      {jobTimeZone(job.published_at)}
                    </p>
                    <p className="text-xl font-semibold">Contact</p>
                    <div className="pt-2 text-gray-500 whitespace-pre-line">
                      {job.company?.address}
                    </div>
                    <div className="flex flex-col space-y-1 py-4">
                      <div>
                        <p className="text-gray-700 fa-solid fa-phone-flip rounded-md fa-1x mr-2"></p>
                        <a
                          className="hover:underline font-semibold text-gray-900"
                          href={`tel:${job.company?.phone}`}
                        >
                          {job.company?.phone}
                        </a>
                      </div>
                      <div>
                        <p className="text-gray-700 fa-solid fa-envelope rounded-md fa-1x mr-2"></p>
                        <a
                          className="hover:underline font-semibold text-gray-900"
                          href={`mailto:${job.company?.email}`}
                        >
                          {job.company?.email}
                        </a>
                      </div>
                      <div>
                        <p className="text-gray-700 fa-solid fa-globe rounded-md fa-1x mr-2"></p>
                        <a
                          className="hover:underline font-semibold text-gray-900"
                          href={job.company?.website}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {job.company?.website}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Link to={`/jobs/${job.id}/form`}>
                <div className="bg-orange-500 flex justify-center rounded py-2 mt-20 mx-auto text-white text-lg font-semibold hover:brightness-75 shadow w-full md:w-2/12">
                  APPLY NOW
                </div>
              </Link>
            </div>
          )}
        </div>
        <div className="mt-8 flex justify-between border-b-2 border-primary-500 text-gray-900 font-semibold">
          <div className="font-semibold text-2xl">{job?.company?.name}</div>
        </div>
        <div className="grid grid-cols-1 gap-4 py-6">
          {jobs &&
            jobs.map((job) => (
              <JobCard key={job.id} job={job} company={job.company} />
            ))}
        </div>
      </div>
    </AppLayout>
  )
}

export default Jobdetail
