import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatDistanceToNow } from 'date-fns'
import { supabase } from '../utils/supabaseCilent'

function JobCard(props) {
  const { job, company } = props
  const [companyLogo, setCompanyLogo] = useState()

  const publishedTimeToNow = formatDistanceToNow(new Date(job.published_at), {
    includeSeconds: true,
  })

  const salaryFormatted = (min, max) => {
    if (min && max) return `${min} - ${max}`
    if (!min && !max) return 'ตามตกลง'
    if (min && !max) return `start ${min}`
    if (!min && max) return `max ${max}`
  }

  useEffect(() => {
    const getFullURL = (imagePath) => {
      const { publicURL } = supabase.storage
        .from('jobs')
        .getPublicUrl(imagePath)

      return publicURL
    }

    if (company?.logo) {
      setCompanyLogo(getFullURL(company?.logo))
    } else {
      setCompanyLogo('https://dummyimage.com/200x200/cccccc/ffffff')
    }
  }, [company])

  return (
    <Link to={`/jobs/${job.id}`}>
      <div className="flex p-4 space-x-4 justify-between rounded shadow bg-white overflow-hidden">
        <div className="flex space-x-4">
          <img
            alt={`${job.name}`}
            className="h-10 w-10 sm:h-24 sm:w-24"
            src={companyLogo}
          />
          <div className="flex-1 font-semibold text-lg md:text-xl">
            <div className="">
              <h3 className="text-sm md:text-base text-gray-900 font-semibold">
                {job.name}
              </h3>
              <div className="text-base md:text-lg text-gray-400">
                <div className="text-xs md:text-sm">{job.level}</div>
                <div className="text-primary-500 text:sm md:text-base">
                  {salaryFormatted(job.salary_min, job.salary_max)}
                </div>
                <div className="text-xs">{job.location}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:block text-right text-sm text-gray-500">
          {publishedTimeToNow}
        </div>
      </div>
    </Link>
  )
}

export default JobCard
