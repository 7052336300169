import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from '../../../utils/supabaseCilent'
import BundledEditor from '../../../component/Hr/BundledEditor'
import HrNavbar from '../../../component/Hr/HrNavbar'

function JobCreate() {
  const [companies, setCompanies] = useState([])
  const [companiesID, setCompaniesID] = useState('1')
  const [name, setName] = useState('')
  const [level, setLevel] = useState('')
  const [salary_min, setSalary_min] = useState(null)
  const [salary_max, setSalary_max] = useState(null)
  const [location, setLocation] = useState('')
  const [date, setDate] = useState(null)
  const [userStatus, setUserStatus] = useState('')
  const editorRef = useRef(null)

  useEffect(() => {
    const getCompanies = async () => {
      const { data } = await supabase
        .from('companies')
        .select(`id,name`)
        .order('id', { ascending: true })
      setCompanies(data)
    }
    getCompanies()
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()

    await supabase.from('jobs').insert({
      name: name,
      level: level,
      salary_min: salary_min,
      salary_max: salary_max,
      location: location,
      published_at: new Date(date),
      description: editorRef.current.getContent(),
      company_id: companiesID,
    })
    navigate('/hr/job')
  }

  let navigate = useNavigate()

  useEffect(() => {
    const fetchdata = async () => {
      const user = supabase.auth.user()
      setUserStatus(user)
    }

    fetchdata()
  }, [userStatus])

  if (!userStatus) {
    navigate('/login')
  }

  return (
    <div className="bg-white">
      <HrNavbar />
      <div className="text-black text-4xl m-5 pl-4 pt-5 max-w-7xl mx-auto">
        Create
      </div>
      <form
        className="px-4 max-w-7xl mx-auto rounded my-5"
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-6 gap-4 pb-7">
          <div className="col-span-6">
            <label className="text-xl">Job name</label>
            <span className="text-red-500"> *</span>
            <br />

            <input
              type="text"
              name="fullname"
              autoComplete="on"
              required
              className="my-2 p-1 w-full rounded bg-gray-100 px-2"
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
          <div className="col-span-6 md:col-span-3">
            <label className="text-xl">Level</label>
            <span className="text-red-500"> *</span>
            <br />
            <select
              name="level"
              required
              onChange={(e) => setLevel(e.target.value)}
              className="my-2 p-1 w-5/6 rounded bg-gray-100 px-2"
            >
              <option></option>
              <option>Entry-Level</option>
              <option>Middle-Level</option>
              <option>Senior-Level</option>
            </select>
          </div>
          <div className="col-span-6 md:col-span-3">
            <label className="text-xl">Company</label>
            <span className="text-red-500"> *</span>
            <br />

            <select
              name="level"
              required
              onChange={(e) => setCompaniesID(e.target.value)}
              className="my-2 p-1 w-5/6 rounded bg-gray-100 px-2"
            >
              {companies &&
                companies.map((company) => (
                  <option value={company?.id} key={company.id}>
                    {company.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-span-6">
            <label className="text-xl">Salary</label>
            <br />
            <label className="mr-2 font-normal text-gray-600">salary_min</label>
            <input
              name="salary_min"
              autoComplete="on"
              placeholder="min"
              className="my-2 p-1 w-3/4 md:w-5/12 rounded bg-gray-100 px-2"
              onChange={(e) => setSalary_min(e.target.value)}
            ></input>
            <label className="mr-1 md:mr-2 ml-0 md:ml-6 font-normal text-gray-600">
              salary_max
            </label>
            <input
              name="salary_max"
              autoComplete="on"
              placeholder="max"
              className="my-2 p-1 w-3/4 md:w-5/12 rounded bg-gray-100 px-2"
              onChange={(e) => setSalary_max(e.target.value)}
            ></input>
          </div>
          <div className="col-span-6">
            <label className="text-xl">Location</label>
            <span className="text-red-500"> *</span>
            <br />
            <textarea
              name="location"
              required
              className="my-2 p-1 w-full rounded bg-gray-100 px-2 resize-none"
              onChange={(e) => setLocation(e.target.value)}
            ></textarea>
          </div>
          <div className="col-span-6">
            <label className="text-xl">Date</label>
            <span className="text-red-500"> *</span>
            <br />
            <label className="mr-2 font-normal text-gray-600">date</label>
            <input
              type="datetime-local"
              name="date"
              autoComplete="on"
              placeholder="date"
              required
              className="p-1 w-1/2 md:w-1/4 rounded bg-gray-100 px-2"
              onChange={(e) => setDate(e.target.value)}
            ></input>
          </div>
          <div className="col-span-6">
            <label className="text-xl">Description</label>
            <br />
            <div className="my-2" />
            <BundledEditor
              onInit={(evt, editor) => (editorRef.current = editor)}
              init={{
                height: 300,
                menubar: true,
                plugins: [
                  'advlist',
                  'anchor',
                  'autolink',
                  'help',
                  'image',
                  'link',
                  'lists',
                  'searchreplace',
                  'table',
                  'wordcount',
                ],
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
          </div>
          <div className="pb-5 col-end-7 col-span-6 md:col-span-1">
            <button
              type="submit"
              className="bg-orange-500 flex justify-center rounded py-2 mx-auto text-white text-lg hover:brightness-75 w-full shadow max-w-xs"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default JobCreate
