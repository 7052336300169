import React, { useEffect, useState } from 'react'
import { supabase } from '../../utils/supabaseCilent'
import { AppLayout } from '../../components/Layouts'
import CompanyCard from '../../components/CompanyCard'
import Input from '../../components/ui/Input'

function Company() {
  const [companies, setCompanies] = useState([])
  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(3)
  const [count, setCount] = useState(0)

  const from = page * perPage
  const to = from + (perPage - 1)
  useEffect(() => {
    const fetchpage = async () => {
      const { data, count } = await supabase
        .from('companies')
        .select('id, logo, name, about, banner', { count: 'exact' })
        .ilike('name', `%${keyword}%`)
        .order('id', { ascending: true })
        .range(from, to)
      setCompanies(data)
      setCount(count)
    }
    fetchpage()
  }, [from, to, keyword])

  const Pagination = () => {
    const totalJobs = Math.ceil(count / perPage)
    const elements = []
    for (let index = 0; index < totalJobs; index++) {
      elements.push(
        <button
          key={index}
          className={`px-2 hover:brightness-50 ${
            index === page ? 'bg-gray-400 text-white' : null
          }`}
          onClick={() => handlePage(index)}
        >
          {index + 1}
        </button>
      )
    }
    return elements
  }

  const handlePage = (page) => {
    setPage(page)
  }

  return (
    <AppLayout>
      <div className="max-w-5xl mx-auto py-6 px-6 lg:px-8">
        <p className="text-2xl uppercase mt-5 md:mt-0 font-semibold text-black text-center md:text-left border-b-2 border-primary-500 pb-2">
          Companies
        </p>
        <div className="max-w-5xl w-full mx-auto mt-4 md:pt-0 text-center md:text-left">
          <Input
            type="search"
            value={keyword}
            placeholder="Search..."
            className="w-full md:w-48"
            onChange={(e) => {
              setKeyword(e.target.value)
              setPage(0)
            }}
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-7 mt-4">
          {companies &&
            companies.map((company) => (
              <CompanyCard key={company.id} company={company} />
            ))}
        </div>
      </div>
      {count > perPage && (
        <div className="py-4 text-center font-semibold text-xl">
          <Pagination />
        </div>
      )}
    </AppLayout>
  )
}

export default Company
