import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { supabase } from '../../../utils/supabaseCilent'
import HrNavbar from '../../../component/Hr/HrNavbar'
import ApplicationFormDelete from './ApplicationFormDelete'

function AllApplicationForm() {
  const [applicationForms, setApplicationForms] = useState([])
  const [keyword, setKeyword] = useState('')
  const [keywordFullName, setKeywordFullName] = useState('')
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [totalJobs, setTotalJobs] = useState(0)
  const [userStatus, setUserStatus] = useState('')
  const [changeStylePrev, setChangeStylePrev] = useState(
    'cursor-not-allowed text-gray-400'
  )
  const [changeStyleNext, setChangeStyleNext] = useState(
    'cursor-not-allowed text-gray-400'
  )
  const [disableButtonPrev, setDisableButtonPrev] = useState(true)
  const [disableButtonNext, setDisableButtonNext] = useState(true)

  const from = page * perPage
  const to = from + (perPage - 1)

  useEffect(() => {
    const fetchdata = async () => {
      const query = supabase

        .from('application_form')
        .select(`*,job:jobs!inner ( name )`, { count: 'exact' })
        .ilike('job.name', `%${keyword}%`)
        .order('created_at', { ascending: false })
        .range(from, to)

      if (keywordFullName) {
        query.ilike('fullname', `%${keywordFullName}%`)
      }

      const { data, count } = await query

      setTotalJobs(count)
      setApplicationForms(data)
      noPressNextPage(count)
    }
    fetchdata()
  }, [from, to, keyword, keywordFullName])

  const DateForm = (publicDateForm) => {
    if (publicDateForm) {
      const jobTime = new Date(publicDateForm)

      return jobTime
        .toLocaleString('en-GB', { timeZone: 'UTC' })
        .substring(0, 10)
    } else {
      return ''
    }
  }

  let navigate = useNavigate()

  useEffect(() => {
    const fetchdata = async () => {
      const user = supabase.auth.user()
      setUserStatus(user)
    }

    fetchdata()
  }, [userStatus])

  if (!userStatus) {
    navigate('/login')
  }

  const noPressNextPage = (total) => {
    const totalPage = Math.ceil(total / perPage)
    nextPage(totalPage, page)
  }

  const nextPage = (totalpage, page) => {
    if (totalpage > 1 && page === 0) {
      setDisableButtonNext(false)
      setChangeStyleNext('')
    }
  }

  const allPage = Math.ceil(totalJobs / perPage)
  const handleNext = (index) => {
    if (index < allPage) {
      setPage(index + 1)
      setDisableButtonPrev(false)
      setChangeStylePrev('')
      if (index === allPage - 2) {
        setChangeStyleNext('cursor-not-allowed text-gray-400')
        setDisableButtonNext(true)
      }
    }
  }

  const handlePrev = (index) => {
    if (index >= 1) {
      setPage(index - 1)
      setDisableButtonNext(false)
      setChangeStyleNext('')
      if (index === 1) {
        setChangeStylePrev('cursor-not-allowed text-gray-400')
        setDisableButtonPrev(true)
      }
    }
  }

  return (
    <div className="bg-white">
      <HrNavbar />
      <div className="max-w-min md:max-w-7xl mx-auto pb-5 overflow-auto">
        <div className="text-black font-normal text-4xl m-5 pt-5">
          ApplicationForm
        </div>
        <div>
          <div className="flex justify-end">
            <input
              className="px-1 md:px-2 text-lg rounded py-1 border-2 border-black"
              type="text"
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value)
                setPage(0)
              }}
              placeholder="Job Title"
            ></input>
          </div>
          <div className="flex justify-end mt-2">
            <input
              className="px-1 md:px-2 text-lg rounded py-1 border-2 border-black"
              type="text"
              value={keywordFullName}
              onChange={(e) => {
                setKeywordFullName(e.target.value)
                setPage(0)
              }}
              placeholder="Name"
            ></input>
          </div>
        </div>
        <table className="p-5 w-full mx-auto max-w-7">
          <thead>
            <tr className="border-b-2 border-black">
              <th className="p-5 text-left">Date</th>
              <th className="p-5 text-center">Job title</th>
              <th className="p-5 text-center">Name</th>
            </tr>
          </thead>
          <tbody>
            {applicationForms &&
              applicationForms.map((form) => (
                <tr key={form.id} className="border-gray-300 border-b-2">
                  <td className="text-left p-5">{DateForm(form.created_at)}</td>
                  <td className="text-center p-5">{form.job?.name}</td>
                  <td className="text-center p-5">{form.fullname}</td>
                  <td className="text-right">
                    <Link to={`/hr/form/detail/${form.id}`}>
                      <i
                        alt="edit"
                        title="edit"
                        className="fa-solid fa-eye text-right text-xl p-5 hover:bg-gray-300"
                      ></i>
                    </Link>
                    <ApplicationFormDelete formDetailId={form.id} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex justify-between pt-5">
          <button disabled={disableButtonPrev} onClick={() => handlePrev(page)}>
            <i
              className={`fa-solid fa-angles-left fa-2x ${changeStylePrev} hover:opacity-50`}
            ></i>
          </button>
          <div className="bg-black font-semibold text-white p-2 rounded">
            {page + 1}
          </div>
          <button disabled={disableButtonNext} onClick={() => handleNext(page)}>
            <i
              className={`fa-solid fa-angles-right fa-2x ${changeStyleNext} hover:opacity-50`}
            ></i>
          </button>
        </div>
      </div>
    </div>
  )
}

export default AllApplicationForm
