import React from 'react'

function Footer() {
  return (
    <div className="bg-gray-900">
      <div className="grid grid-cols-6 max-w-5xl mx-auto py-6mx-auto py-6 lg:mt-4 px-6 lg:px-8">
        <div className="col-span-6 flex justify-start">
          <div>
            <div className="flex flex-col md:flex-row space-y-4 md:space-x-4 md:space-y-0">
              <a href="/">
                <div className="w-20">
                  <img alt="CISSA Logo" src="/assets/images/cissa-logo.png" />
                </div>
              </a>
              <div className="grid gap-4">
                <div>
                  <h4 className="text-white font-semibold">
                    CISSA GROUP CO., LTD.
                  </h4>
                  <div className="text-sm mt-2 text-gray-400 w-1/2">
                    Bangkok Office Unit 193/143 35th Fl (Zone D), Lake Rajada
                    Office Complex Building, Ratchadapisek Road, Klong Toey,
                    Bangkok 10110.
                  </div>
                </div>
                <div className="grid gap-6 grid-cols-6">
                  <div className="col-span-6 md:col-span-5">
                    <b className="text-white text-sm">Contact Us</b>
                    <br />
                    <div className="grid gap-4 grid-cols-12 items-center">
                      <div className="col-span-6 md:col-span-2">
                        <div className="w-[76px] h-[76px] p-1 bg-white">
                          <img
                            src="/assets/images/qr-line-hr-dept.jpeg"
                            alt="LINE"
                          />
                        </div>
                      </div>
                      <div className="col-span-12 md:col-span-5">
                        <div className="flex flex-col mt-1">
                          <a href="mailto:jobs@cissagroup.com">
                            <i className="fa-1x fa fa-envelope text-gray-300 pr-2"></i>
                            <span className="hover:underline text-gray-400">
                              jobs@cissagroup.com
                            </span>
                          </a>
                          <a href="mailto:julalak.y@cissagroup.com">
                            <i className="fa-1x fa fa-envelope text-gray-300 pr-2"></i>
                            <span className="hover:underline text-gray-400">
                              julalak.y@cissagroup.com
                            </span>
                          </a>
                        </div>
                      </div>
                      <div className="col-span-12 md:col-span-5">
                        <div className="flex flex-col mt-1">
                          <a href="tel:+6620265329">
                            <i className="fa-1x fa fa-phone text-gray-300 pr-2"></i>
                            <span className="hover:underline text-gray-400">
                              02 026 5329
                            </span>
                          </a>
                          <a href="tel:+66818959991">
                            <i className="fa-1x fa fa-mobile-alt text-gray-300 pr-2"></i>
                            <span className="hover:underline text-gray-400">
                              081 895 9991
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid gap-6 grid-cols-6">
                  <div className="col-span-6 md:col-span-1">
                    <b className="text-sm text-white">Follow Us</b>
                    <div className="flex w-full mt-2 space-x-2 text-white">
                      <a
                        href="https://www.facebook.com/CissaGroup/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="flex w-8 h-8 bg-blue-500 hover:bg-blue-600 rounded-full items-center justify-center">
                          <i className="fa-1x fa-brands fa-facebook-f"></i>
                        </div>
                      </a>
                      <a
                        href="https://th.linkedin.com/company/cissagroup"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="flex w-8 h-8 bg-blue-900 hover:bg-blue-800 rounded-full items-center justify-center">
                          <i className="fa-1x fa-brands fa-linkedin-in"></i>
                        </div>
                      </a>
                      <a
                        href="https://line.me/ti/p/j-hbd5X0xm"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="flex">
                          <i
                            className="fa-1x fa-brands fa-line text-[34px]"
                            style={{
                              color: '#1fae4a',
                            }}
                          ></i>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
