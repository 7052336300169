import React from 'react'
import { Link } from 'react-router-dom'
import LogOut from '../../page/auth/LogOut'

function HrNavbar() {
  return (
    <div>
      <div className="bg-black">
        <nav className="max-w-full md:max-w-5xl mx-auto flex justify-between">
          <nav className="flex">
            <Link
              to="/"
              className="flex-none text-xl font-bold p-4 text-right text-white hover:text-gray-300 overflow-hidden"
            >
              Home
            </Link>
            <LogOut />
          </nav>
          <nav className="flex overflow-hidden">
            <Link
              className="text-xl p-4 font-bold text-right text-white hover:text-gray-300 overflow-hidden"
              to="/hr/job"
            >
              Jobs
            </Link>
            <Link
              className="text-xl p-4 font-bold text-left text-white hover:text-gray-300 overflow-hidden"
              to="/hr/company"
            >
              Companies
            </Link>
            <Link
              className="text-xl p-4 font-bold text-left text-white hover:text-gray-300 overflow-hidden"
              to="/hr/form"
            >
              ApplicationForm
            </Link>
            <Link
              className="text-xl p-4 font-bold text-left text-white hover:text-gray-300 overflow-hidden"
              to="/hr"
            >
              Dashboard
            </Link>
          </nav>
        </nav>
      </div>
    </div>
  )
}
export default HrNavbar
