import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { supabase } from '../../../utils/supabaseCilent'
import HrNavbar from '../../../component/Hr/HrNavbar'
import CompanyDelete from './CompanyDelete'

function AllCompanies() {
  const [companies, setCompanies] = useState([])
  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [totalJobs, setTotalJobs] = useState(0)
  const [userStatus, setUserStatus] = useState('')
  const [changeStylePrev, setChangeStylePrev] = useState(
    'cursor-not-allowed text-gray-400'
  )
  const [changeStyleNext, setChangeStyleNext] = useState(
    'cursor-not-allowed text-gray-400'
  )
  const [disableButtonPrev, setDisableButtonPrev] = useState(true)
  const [disableButtonNext, setDisableButtonNext] = useState(true)

  const from = page * perPage
  const to = from + (perPage - 1)

  useEffect(() => {
    const fetchdata = async () => {
      const { data, count } = await supabase
        .from('companies')
        .select('*', { count: 'exact' })
        .order('id', { ascending: true })
        .ilike('name', `%${keyword}%`)
        .range(from, to)

      setTotalJobs(count)
      setCompanies(data)
      noPressNextPage(count)
    }
    fetchdata()
  }, [from, to, keyword])

  const noPressNextPage = (total) => {
    const totalPage = Math.ceil(total / perPage)
    nextPage(totalPage, page)
  }

  const nextPage = (totalpage, page) => {
    if (totalpage > 1 && page === 0) {
      setDisableButtonNext(false)
      setChangeStyleNext('')
    }
  }

  const allPage = Math.ceil(totalJobs / perPage)
  const handleNext = (index) => {
    if (index < allPage) {
      setPage(index + 1)
      setDisableButtonPrev(false)
      setChangeStylePrev('')
      if (index === allPage - 2) {
        setChangeStyleNext('cursor-not-allowed text-gray-400')
        setDisableButtonNext(true)
      }
    }
  }

  const handlePrev = (index) => {
    if (index >= 1) {
      setPage(index - 1)
      setDisableButtonNext(false)
      setChangeStyleNext('')
      if (index === 1) {
        setChangeStylePrev('cursor-not-allowed text-gray-400')
        setDisableButtonPrev(true)
      }
    }
  }

  let navigate = useNavigate()

  useEffect(() => {
    const fetchdata = async () => {
      const user = supabase.auth.user()
      setUserStatus(user)
    }

    fetchdata()
  }, [userStatus])

  if (!userStatus) {
    navigate('/login')
  }

  return (
    <div className="bg-white">
      <HrNavbar />
      <div className="max-w-xs md:max-w-7xl mx-auto pb-5">
        <div className="text-black font-normal text-4xl p-5 flex">
          <p className="my-auto flex flex-nPrevsetDisableButtonPrev">
            Companies
          </p>
          <p className="grow"></p>
          <Link to={`/hr/company/create`}>
            <span>
              <i
                alt="creat"
                title="creat"
                className="fa-solid fa-circle-plus text-right text-xl p-5 hover:bg-gray-300 justify-end"
              ></i>
            </span>
          </Link>
        </div>
        <div className="flex justify-end">
          <input
            className="px-1 md:px-2 text-lg rounded py-1 border-2 border-black"
            type="text"
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value)
              setPage(0)
            }}
            placeholder="Company name"
          ></input>
        </div>
        <table className="p-5 w-full mx-auto">
          <thead>
            <tr className="border-b-2 border-black">
              <th className="p-5 text-left">Company Name</th>
            </tr>
          </thead>
          <tbody>
            {companies &&
              companies.map((company) => (
                <tr key={company.id} className="border-gray-300 border-b-2">
                  <td className="text-left p-5">{company.name}</td>
                  <td className="text-right">
                    <Link to={`/hr/company/edit/${company.id}`}>
                      <i
                        alt="edit"
                        title="edit"
                        className="fa-solid fa-pen-to-square text-right text-xl p-5 hover:bg-gray-300"
                      ></i>
                    </Link>
                    <CompanyDelete
                      pathbanner={company.banner}
                      companyId={company.id}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex justify-between pt-5">
          <button disabled={disableButtonPrev} onClick={() => handlePrev(page)}>
            <i
              className={`fa-solid fa-angles-left fa-2x ${changeStylePrev} hover:opacity-50`}
            ></i>
          </button>
          <div className="bg-black font-semibold text-white p-2 rounded">
            {page + 1}
          </div>
          <button disabled={disableButtonNext} onClick={() => handleNext(page)}>
            <i
              className={`fa-solid fa-angles-right fa-2x ${changeStyleNext} hover:opacity-50`}
            ></i>
          </button>
        </div>
      </div>
    </div>
  )
}

export default AllCompanies
