import React from 'react'
import { supabase } from '../../../utils/supabaseCilent'

function JobDelete(props) {
  const { jobId } = props

  const handleClick = async () => {
    if (window.confirm('คุณต้องการลบข้อมูลใช่ไหม') === true) {
      const { error } = await supabase
        .from('jobs')
        .delete()
        .match({ id: jobId })
      if (error) {
        alert('ไม่สามารถลบข้อมูลนี้ได้กรุณาตรวจสอบว่ามีไฟล์ตกค้างหรือไม่')
      }
      window.location.reload()
    } else {
    }
  }

  return (
    <button onClick={handleClick}>
      <span className="inline-table">
        <i className="fa-solid fa-trash text-right text-xl p-5 hover:bg-gray-300"></i>
      </span>
    </button>
  )
}

export default JobDelete
