import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser'
import { AppLayout } from '../../components/Layouts'
import JobCard from '../../components/JobCard'
import { supabase } from '../../utils/supabaseCilent'

const CompanyDetail = () => {
  const [company, setCompany] = useState()
  const [companyLogo, setCompanyLogo] = useState()
  const [companyBanner, setCompanyBanner] = useState()
  const { id } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await supabase
        .from('companies')
        .select(`*, jobs ( * ) `)
        .eq('id', id)
        .single()
        .order('published_at', { foreignTable: 'jobs', ascending: false })
        .lte('jobs.published_at', new Date().toISOString())

      setCompany(data)
    }

    if (id) {
      fetchData()
    }
  }, [id])

  useEffect(() => {
    const getLogoFullURL = (imagePath) => {
      const { publicURL } = supabase.storage
        .from('jobs')
        .getPublicUrl(imagePath)

      return publicURL
    }

    if (company?.logo) {
      setCompanyLogo(getLogoFullURL(company.logo))
    } else {
      setCompanyLogo('https://dummyimage.com/200x200/cccccc/cccccc')
    }
  }, [company])

  useEffect(() => {
    const getBannerFullURL = (imagePath) => {
      const { publicURL } = supabase.storage
        .from('jobs')
        .getPublicUrl(imagePath)

      return publicURL
    }

    if (company?.banner) {
      setCompanyBanner(getBannerFullURL(company.banner))
    } else {
      setCompanyBanner('https://dummyimage.com/800x600/cccccc/cccccc')
    }
  }, [company])

  return (
    <AppLayout>
      <div className="max-w-5xl mx-auto py-6 lg:mt-4 px-6 lg:px-8">
        {company && (
          <div className="block bg-white rounded shadow">
            <div
              className="flex h-48 sm:h-64 md:h-96 bg-cover bg-center bg-no-repeat rounded-t"
              style={{
                backgroundImage: `url(${companyBanner ?? ''})`,
              }}
            />
            <div className="flex justify-center md:justify-start -mt-24 md:ml-4">
              <img
                className="w-28"
                src={companyLogo}
                alt={`${company.name} Logo`}
              />
            </div>
            <div className="p-6 mt-6 grid grid-cols-3 gap-4">
              <div className="col-span-3 md:col-span-2">
                <h2 className="pb-4 text-gray-900 text-2xl font-semibold">
                  {company.name}
                </h2>
                <div>{parse(company?.description || '')}</div>
              </div>
              <div className="col-span-3 md:col-span-1">
                <div className="border-t-2 visible md:invisible" />
                <div className="md:border-l-2 border-gray-300 md:px-5 pt-2 md:pt-0">
                  <p className="text-xl font-semibold">Contact</p>
                  <div className="pt-2 text-gray-500 whitespace-pre-line">
                    {company.address}
                  </div>
                  <div className="flex flex-col space-y-1 py-4">
                    <div>
                      <p className="text-gray-700 fa-solid fa-phone-flip rounded-md fa-1x mr-2"></p>
                      <a
                        className="hover:underline font-semibold text-gray-900"
                        href={`tel:${company.phone}`}
                      >
                        {company.phone}
                      </a>
                    </div>
                    <div>
                      <p className="text-gray-700 fa-solid fa-envelope rounded-md fa-1x mr-2"></p>
                      <a
                        className="hover:underline font-semibold text-gray-900"
                        href={`mailto:${company.email}`}
                      >
                        {company.email}
                      </a>
                    </div>
                    <div>
                      <p className="text-gray-700 fa-solid fa-globe rounded-md fa-1x mr-2"></p>
                      <a
                        className="hover:underline font-semibold text-gray-900"
                        href={company.website}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {company.website}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {company?.jobs?.length ? (
          <div className="mt-8">
            <div className="flex justify-between border-b-2 border-primary-500 text-gray-900 font-semibold">
              <div className="font-semibold text-black text-2xl uppercase">
                All jobs
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 py-6">
              {company?.jobs &&
                company?.jobs?.map((job) => (
                  <JobCard key={job.id} job={job} company={company} />
                ))}
            </div>
          </div>
        ) : null}
      </div>
    </AppLayout>
  )
}

export default CompanyDetail
