import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { supabase } from '../utils/supabaseCilent'

function CompanyCard(props) {
  const [companyBannerUrl, setCompanyBannerUrl] = useState()

  const { company } = props

  useEffect(() => {
    const getFullURL = (imagePath) => {
      const { publicURL } = supabase.storage
        .from('jobs')
        .getPublicUrl(imagePath)

      return publicURL
    }

    if (company.banner) {
      setCompanyBannerUrl(getFullURL(company.banner))
    } else {
      setCompanyBannerUrl('https://dummyimage.com/800x600/cccccc/ffffff')
    }
  }, [company])

  return (
    <div className="bg-white rounded overflow-hidden shadow">
      <Link to={`/companies/${company.id}`}>
        <img src={companyBannerUrl} alt={`${company.name} 's logo`} />
        <div className="flex space-y-1 flex-col px-6 py-4">
          <div className=" text-gray-900 text-xl font-semibold">
            {company.name}
          </div>
          {company.about && (
            <div className="text-gray-500 text-sm">{company.about}</div>
          )}
        </div>
      </Link>
    </div>
  )
}

export default CompanyCard
