import React from 'react'
import { supabase } from '../../../utils/supabaseCilent'

function ApplicationFormDelete(props) {
  const { formDetailId } = props
  const handleDelete = async () => {
    if (window.confirm('คุณต้องการลบข้อมูลใช่ไหม') === true) {
      await supabase
        .from('application_form')
        .delete()
        .match({ id: formDetailId })
    }
    window.location.reload()
  }
  return (
    <button onClick={handleDelete}>
      <span className="inline-table">
        <i className="fa-solid fa-trash text-right text-xl p-5 hover:bg-gray-300"></i>
      </span>
    </button>
  )
}

export default ApplicationFormDelete
