import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from '../../../utils/supabaseCilent'
import BundledEditor from '../../../component/Hr/BundledEditor'
import HrNavbar from '../../../component/Hr/HrNavbar'
import ImageUpload from '../../../component/Hr/ImageUpload'

function CompanyCreate() {
  const [name, setName] = useState('')
  const [about, setAbout] = useState('')
  const [address, setAddress] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [website, setWebsite] = useState('')
  const [bannerInDatabase, setBannerInDatabase] = useState('')
  const [bannerurl, setBannerUrl] = useState('')
  const [logourl, setLogoUrl] = useState('')
  const [logoInDatabase, setLogoInDatabase] = useState('')
  const [userStatus, setUserStatus] = useState('')
  const editorRef = useRef(null)

  const handleSubmit = async (event) => {
    event.preventDefault()

    await supabase.from('companies').insert({
      name: name,
      description: editorRef.current.getContent(),
      address: address,
      phone: phone,
      email: email,
      website: website,
      banner: bannerInDatabase,
      logo: logoInDatabase,
      about: about,
    })
    navigate('/hr/company')
  }

  let navigate = useNavigate()

  useEffect(() => {
    const fetchdata = async () => {
      const user = supabase.auth.user()
      setUserStatus(user)
    }

    fetchdata()
  }, [userStatus])

  if (!userStatus) {
    navigate('/login')
  }

  return (
    <div className="bg-white">
      <HrNavbar />
      <div className="text-black text-4xl m-5 pl-4 pt-5 max-w-lg md:max-w-7xl mx-auto">
        Create
      </div>
      <form
        className="px-4 max-w-lg md:max-w-7xl mx-auto rounded my-5"
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-6 gap-4 pb-7">
          <div className="col-span-6">
            <label className="text-xl">Company name</label>
            <span className="text-red-500"> *</span>
            <br />
            <input
              type="text"
              name="fullname"
              autoComplete="on"
              required
              value={name}
              className="my-2 p-1 w-full rounded bg-gray-100 px-2"
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
          <div className="col-span-6">
            <label className="text-xl">Contact</label>
            <span className="text-red-500"> *</span>
            <br />
            <label className="mr-2 font-normal text-gray-600">Phone</label>
            <input
              name="phone"
              autoComplete="on"
              pattern="[0-9-]{1,}"
              title="กรอกเฉพาะตัวเลขเท่านั้น"
              required
              value={phone}
              className="my-2 p-1 w-5/6 md:w-1/4 rounded bg-gray-100 px-2"
              onChange={(e) => setPhone(e.target.value)}
            ></input>
            <label className="mr-2 ml-0 md:ml-6 font-normal text-gray-600">
              Email
            </label>
            <input
              name="email"
              autoComplete="on"
              type="email"
              required
              value={email}
              className="mx-1 my-2 p-1 w-5/6 md:w-1/4 rounded bg-gray-100 px-2"
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <label className="mr-2 ml-0 md:ml-6 font-normal text-gray-600">
              Website
            </label>
            <input
              name="website"
              autoComplete="on"
              required
              value={website}
              className="mx-2 md:mx-1 p-1 w-3/4 md:w-1/4 rounded bg-gray-100 px-2"
              onChange={(e) => setWebsite(e.target.value)}
            ></input>
          </div>
          <div className="col-span-6">
            <label className="text-xl">About Company</label>
            <span className="text-red-500"> *</span>
            <br />
            <textarea
              name="about"
              required
              value={about}
              maxLength="100"
              className="mt-2 pt-1 w-full rounded bg-gray-100 px-2 resize-none h-20"
              onChange={(e) => setAbout(e.target.value)}
            ></textarea>
            <small className="text-red-500 font-semibold">
              จำกัดตัวอักษร 100 อักษร
            </small>
          </div>
          <div className="col-span-6">
            <label className="text-xl">Address</label>
            <span className="text-red-500"> *</span>
            <br />
            <textarea
              name="address"
              value={address}
              required
              className="my-2 p-1 w-full rounded bg-gray-100 px-2 resize-none"
              onChange={(e) => setAddress(e.target.value)}
            ></textarea>
          </div>
          <div className="col-span-6">
            <label className="text-xl">Description</label>
            <span className="text-red-500"> *</span>
            <br />
            <div className="my-2" />
            <BundledEditor
              onInit={(evt, editor) => (editorRef.current = editor)}
              init={{
                height: 300,
                menubar: true,
                plugins: [
                  'advlist',
                  'anchor',
                  'autolink',
                  'help',
                  'image',
                  'link',
                  'lists',
                  'searchreplace',
                  'table',
                  'wordcount',
                ],
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
          </div>
          <div className="col-span-6 md:col-span-3">
            <label className="text-xl">Banner</label>
            <span className="text-red-500"> *</span>
            <ImageUpload
              url={bannerurl}
              TitleAlt="Banner"
              pathURL="banner-photo/create/"
              onUpload={(url) => {
                setBannerUrl(url)
                setBannerInDatabase(url)
              }}
            />
          </div>
          <div className="col-span-6 md:col-span-3">
            <label className="text-xl">Logo</label>
            <span className="text-red-500"> *</span>
            <ImageUpload
              url={logourl}
              TitleAlt="logo"
              pathURL="logo-photo/create/"
              onUpload={(url) => {
                setLogoUrl(url)
                setLogoInDatabase(url)
              }}
            />
          </div>
          <div className="pb-5 col-end-7 col-span-6 md:col-span-1">
            <button
              type="submit"
              className="bg-orange-500 flex justify-center rounded py-2 mx-auto text-white text-lg hover:brightness-75 w-full shadow max-w-xs"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CompanyCreate
