import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'

export const AppLayout = ({ children }) => {
  return (
    <>
      <div className="flex flex-col min-h-screen antialiased text-gray-900 bg-gray-50">
        <Navbar />
        <div className="flex-1">
          <main>{children}</main>
        </div>
        <Footer />
      </div>
    </>
  )
}
