import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { supabase } from '../../../utils/supabaseCilent'
import HrNavbar from '../../../component/Hr/HrNavbar'

function ApplicationFormDetail() {
  const { id } = useParams()
  const [forms, setForms] = useState({})
  const [profile, setProfile] = useState('')
  const [profileFullURL, setProFileFullURL] = useState('')
  const [resume, setResume] = useState('')
  const [resumeFullURL, setResumeFullURL] = useState('')
  const [userStatus, setUserStatus] = useState('')

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await supabase
        .from('application_form')
        .select(`*,job:jobs ( name )`)
        .eq('id', id)
        .single()

      setForms(data)
      setProfile(data.profile)
      setResume(data.resume)
    }
    fetchData()
  }, [id])

  const DateForm = (publicDateForm) => {
    if (publicDateForm) {
      const jobTime = new Date(publicDateForm)

      return jobTime
        .toLocaleString('en-GB', { timeZone: 'UTC' })
        .substring(0, 10)
    } else {
      return ''
    }
  }

  useEffect(() => {
    if (profile) ShowProfile(profile)
  }, [profile])

  const ShowProfile = async (pathProfile) => {
    const { publicURL } = supabase.storage
      .from('jobs')
      .getPublicUrl(pathProfile)
    setProFileFullURL(publicURL)
  }

  useEffect(() => {
    if (resume) downloadResume(resume)
  }, [resume])

  const downloadResume = async (pathResume) => {
    const { publicURL } = supabase.storage.from('jobs').getPublicUrl(pathResume)
    setResumeFullURL(publicURL)
  }

  const handleBack = () => {
    navigate('/hr/form')
  }

  const handleDelete = async () => {
    if (window.confirm('คุณต้องการลบข้อมูลใช่ไหม') === true) {
      await supabase.from('application_form').delete().match({ id: forms.id })
    }
    navigate('/hr/form')
  }

  let navigate = useNavigate()

  useEffect(() => {
    const fetchdata = async () => {
      const user = supabase.auth.user()
      setUserStatus(user)
    }

    fetchdata()
  }, [userStatus])

  if (!userStatus) {
    navigate('/login')
  }

  return (
    <>
      <HrNavbar />
      <div className="bg-white max-w-full md:max-w-7xl mx-auto">
        <form className="mx-4 rounded mt-5">
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-6 flex justify-between">
              <div className="text-black text-4xl pt-5 ">Details</div>
              <div className="w-24 md:w-40">
                <img src={profileFullURL} alt="profile_image" />
              </div>
            </div>
            <div className="col-span-6">
              <label className="text-xl pr-5">Apply on :</label>
              <input
                type="text"
                autoComplete="on"
                defaultValue={DateForm(forms.created_at)}
                readOnly
                className="my-2 p-1 w-fit rounded text-center bg-gray-100 px-2"
              ></input>
            </div>
            <div className="col-span-6">
              <label className="text-xl">Job title</label>
              <br />
              <input
                type="text"
                autoComplete="on"
                defaultValue={forms.job?.name}
                readOnly
                className="my-2 p-1 w-full rounded bg-gray-100 px-2"
              ></input>
            </div>
            <div className="col-span-6">
              <label className="text-xl">Name</label>
              <br />
              <input
                type="text"
                autoComplete="on"
                defaultValue={forms.fullname}
                readOnly
                className="my-2 p-1 w-full rounded bg-gray-100 px-2"
              ></input>
            </div>
            <div className="col-span-6 md:col-span-2">
              <label className="text-xl">Sex : </label>
              <input
                type="text"
                autoComplete="on"
                defaultValue={forms.sex}
                readOnly
                className="my-2 p-1 w-1/4 text-center rounded bg-gray-100 px-2"
              ></input>
            </div>
            <div className="col-span-6 md:col-span-4">
              <label className="text-xl">Birthday : </label>
              <input
                type="text"
                autoComplete="on"
                defaultValue={
                  forms.date_day === null ? 'ไม่ระบุ' : forms.date_day
                }
                readOnly
                className="my-2 p-1 w-1/6 mr-4 text-center rounded bg-gray-100 px-2"
              ></input>
              /
              <input
                type="text"
                autoComplete="on"
                defaultValue={
                  forms.date_month === null ? 'ไม่ระบุ' : forms.date_month
                }
                readOnly
                className="my-2 p-1 w-1/6 mx-4 text-center rounded bg-gray-100 px-2"
              ></input>
              /
              <input
                type="text"
                autoComplete="on"
                defaultValue={
                  forms.date_year === null ? 'ไม่ระบุ' : forms.date_year
                }
                readOnly
                className="my-2 p-1 w-1/6 ml-4 text-center rounded bg-gray-100 px-2"
              ></input>
            </div>
            <div className="col-span-6">
              <label className="text-xl">ID card number</label>
              <br />
              <input
                type="text"
                autoComplete="on"
                defaultValue={forms.id_card}
                readOnly
                className="my-2 p-1 w-full rounded bg-gray-100 px-2"
              ></input>
            </div>
            <div className="col-span-6">
              <label className="text-xl">Email</label>
              <br />
              <input
                type="text"
                autoComplete="on"
                defaultValue={forms.email}
                readOnly
                className="my-2 p-1 w-full rounded bg-gray-100 px-2"
              ></input>
            </div>
            <div className="col-span-6">
              <label className="text-xl">Phone</label>
              <br />
              <input
                type="text"
                autoComplete="on"
                defaultValue={forms.phone}
                readOnly
                className="my-2 p-1 w-full rounded bg-gray-100 px-2"
              ></input>
            </div>
            <div className="col-span-6">
              <label className="text-xl">Address</label>
              <br />
              <textarea
                name="description"
                defaultValue={forms.address}
                readOnly
                className="my-2 p-1 w-full rounded bg-gray-100 px-2 resize-none h-36"
              ></textarea>
            </div>
          </div>
        </form>
        <div className="grid grid-cols-6 gap-4 pb-12 px-4">
          <div className="col-span-6">
            <button className="w-full md:w-auto">
              <a
                target="blank"
                href={`${resumeFullURL}`}
                className="bg-green-600 text-center rounded p-2 mx-auto text-white text-lg hover:brightness-75 shadow"
              >
                Download Resume
              </a>
            </button>
          </div>
          <div className="col-span-2 md:col-span-1 mt-6">
            <button
              onClick={handleBack}
              className="bg-orange-500 flex justify-center rounded py-2 mx-auto text-white text-lg hover:brightness-75 w-full shadow max-w-xs"
            >
              Back
            </button>
          </div>
          <div className="mt-6 col-span-2 md:col-span-1 col-end-7 md:col-end-7">
            <button
              type="submit"
              onClick={handleDelete}
              className="bg-orange-500 flex justify-center rounded py-2 mx-auto text-white text-lg hover:brightness-75 w-full shadow max-w-xs"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ApplicationFormDetail
