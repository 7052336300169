// Carousel.jsx
import clsx from 'clsx'
import React from 'react'
import { useSnapCarousel } from 'react-snap-carousel'

const Carousel = ({ items, renderItem }) => {
  const { scrollRef, prev, next, snapPointIndexes } = useSnapCarousel()

  return (
    <div>
      <ul
        className="flex overflow-x-scroll md:overflow-x-hidden scroll-smooth space-x-4 py-4 px-2 -mx-2 snap-mandatory touch-pan-x"
        ref={scrollRef}
      >
        {items.map((item, i) =>
          renderItem({
            item,
            isSnapPoint: snapPointIndexes.has(i),
          })
        )}
      </ul>
      <div className="md:flex justify-center hidden" aria-hidden>
        <div className="space-x-4">
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={() => prev()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
              />
            </svg>
          </button>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={() => next()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

const CarouselItem = ({ isSnapPoint, children }) => (
  <li
    className={clsx(
      'w-full sm:w-1/2 md:w-1/4 flex-shrink-0',
      isSnapPoint & 'snap-start'
    )}
  >
    {children}
  </li>
)

export { Carousel, CarouselItem }
