import React from 'react'

const Input = React.forwardRef((props, ref) => {
  const { id, label, helpText, error, className, onFocus, required, ...rest } =
    props
  return (
    <div>
      {label && (
        <label htmlFor={id} className="block font-medium text-md text-gray-700">
          {label}
          {required && <span className="mt-2 text-sm text-red-600">*</span>}
        </label>
      )}
      <input
        id={id}
        required={required}
        onFocus={onFocus}
        className={`${className} rounded shadow-sm border-gray-300 focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50`}
        ref={ref}
        {...rest}
      />
      {helpText && (
        <p className={`mt-2 text-sm ${error ? 'text-red-600' : ''}`}>
          {helpText}
        </p>
      )}
    </div>
  )
})

export default Input
