import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AppLayout } from '../components/Layouts'
import CompanyCard from '../components/CompanyCard'
import JobCard from '../components/JobCard'
import { supabase } from '../utils/supabaseCilent'
import EmployeeBenefits from '../components/home/EmployeeBenefits'

function Home() {
  const [jobs, setJobs] = useState([])
  const [companies, setCompanies] = useState([])
  const now = new Date().toISOString()

  useEffect(() => {
    const getJobs = async () => {
      const { data } = await supabase
        .from('jobs')
        .select(`*, company:companies ( logo ) `)
        .order('published_at', { ascending: false })
        .lte('published_at', now)
        .limit(10)
      setJobs(data)
    }

    getJobs()
  }, [])

  useEffect(() => {
    const getCompanies = async () => {
      const { data } = await supabase
        .from('companies')
        .select(`id,name,description,banner`)
        .order('id', { ascending: true })
        .limit(3)
      setCompanies(data)
    }
    getCompanies()
  }, [])

  return (
    <AppLayout>
      <div className="max-w-5xl mx-auto py-6 px-6 lg:px-8">
        <div className="flex items-baseline justify-between mt-5 md:mt-0 font-semibold text-black text-center md:text-left border-b-2 border-primary-500 pb-2">
          <h3 className="text-2xl uppercase">Companies</h3>
          <Link
            to="companies"
            className="uppercase col-span-2 md:col-span-1 font-semibold text-right hover:text-gray-500"
          >
            See all companies
          </Link>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-6">
          {companies &&
            companies.map((company) => (
              <CompanyCard key={company.id} company={company} />
            ))}
        </div>
        <EmployeeBenefits />
        <div className="flex justify-between border-b-2 border-primary-500 text-gray-900 font-semibold mt-4">
          <div className="text-xl md:text-2xl font-semibold uppercase">
            Latest Jobs
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 py-6">
          {jobs &&
            jobs.map((job) => (
              <JobCard key={job.id} job={job} company={job.company} />
            ))}
        </div>
        <div className="text-black font-semibold text-right">
          <a href="jobs" className="text-base hover:text-gray-500">
            SEE ALL JOBS
          </a>
        </div>
      </div>
    </AppLayout>
  )
}

export default Home
