import React from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from '../../utils/supabaseCilent'

function LogOut() {
  let navigate = useNavigate()
  const handleClick = async () => {
    await supabase.auth.signOut()
    navigate('/login')
  }
  return (
    <>
      <button
        onClick={handleClick}
        className="border-l-2 border-white text-xl font-bold p-4 text-right text-white hover:text-gray-300 overflow-hidden"
      >
        Log out
      </button>
    </>
  )
}

export default LogOut
