import { React, useEffect, useState } from 'react'
import { supabase } from '../../utils/supabaseCilent'
import { AppLayout } from '../../components/Layouts'
import JobCard from '../../components/JobCard'
import Input from '../../components/ui/Input'

function Joblist() {
  const [jobs, setJobs] = useState([])
  const [keyword, setKeyword] = useState('')
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [totalJobs, setTotalJobs] = useState(0)
  const [jobLevel, setJobLevel] = useState()

  const from = page * perPage
  const to = from + (perPage - 1)
  const now = new Date().toISOString()

  useEffect(() => {
    const fetchpage = async () => {
      const query = supabase
        .from('jobs')
        .select(
          `*,
        company:companies ( logo ) `,
          { count: 'exact' }
        )
        .ilike('name', `%${keyword}%`)
        .order('published_at', { ascending: false })
        .lte('published_at', now)
        .range(from, to)

      if (jobLevel) {
        query.eq('level', jobLevel)
      }

      const { data: jobs, count } = await query

      setJobs(jobs)
      setTotalJobs(count)
    }

    fetchpage()
  }, [from, to, keyword, jobLevel])

  const handlePage = (page) => {
    setPage(page)
  }

  const handleLevelChange = (e) => {
    setJobLevel(e.target.value)
    setPage(0)
  }

  const Pagination = () => {
    const totalPage = Math.ceil(totalJobs / perPage)

    const elements = []

    for (let index = 0; index < totalPage; index++) {
      elements.push(
        <button
          key={index}
          className={`px-2 hover:brightness-50 ${
            index === page ? 'bg-gray-400 text-white' : null
          }`}
          onClick={() => handlePage(index)}
        >
          {index + 1}
        </button>
      )
    }

    return elements
  }

  return (
    <AppLayout>
      <div className="max-w-5xl mx-auto py-6 px-6 lg:px-8">
        <p className="text-2xl mt-5 md:mt-0 font-semibold text-black text-center md:text-left border-b-2 border-primary-500 pb-2">
          JOBS
        </p>
        <div className="max-w-5xl w-full mx-auto mt-4 md:pt-0 text-center md:text-left">
          <Input
            type="search"
            value={keyword}
            placeholder="Search..."
            className="w-full md:w-48"
            onChange={(e) => {
              setKeyword(e.target.value)
              setPage(0)
            }}
          />
        </div>
        <div className="col-span-3 md:col-span-2">
          <div className="my-4">
            <label className="text-xl md:text-lg font-semibold">Level</label>
            <p className="flex space-x-2">
              <label className="inline-flex items-center">
                <input
                  className="accent-red-500 mr-2"
                  type="radio"
                  name="choice"
                  value="Entry-Level"
                  onClick={handleLevelChange}
                ></input>
                Entry
              </label>
              <label className="inline-flex items-center">
                <input
                  className="accent-blue-500 mr-2"
                  type="radio"
                  name="choice"
                  value="Middle-Level"
                  onClick={handleLevelChange}
                ></input>
                Middle
              </label>
              <label className="inline-flex items-center">
                <input
                  className="accent-red-700 mr-2"
                  type="radio"
                  name="choice"
                  value="Senior-Level"
                  onClick={handleLevelChange}
                ></input>
                Senior
              </label>
              <label className="inline-flex items-center">
                <input
                  className="accent-violet-500 mr-2"
                  type="radio"
                  name="choice"
                  value=""
                  onClick={handleLevelChange}
                ></input>
                All level
              </label>
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4">
          {jobs &&
            jobs.map((job) => (
              <JobCard key={job.id} job={job} company={job.company} />
            ))}
        </div>
      </div>
      {totalJobs > perPage && (
        <div className="py-4 text-center font-semibold text-xl">
          <Pagination />
        </div>
      )}
    </AppLayout>
  )
}

export default Joblist
