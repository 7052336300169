import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { supabase } from '../../utils/supabaseCilent'

function LogIn() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [userStatus, setUserStatus] = useState('')

  let navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    const { user } = await supabase.auth.signIn({
      email: email,
      password: password,
    })
    if (user) {
      navigate('/hr')
    } else {
      alert('Invalid password')
    }
  }

  useEffect(() => {
    const fetchdata = async () => {
      const user = supabase.auth.user()
      setUserStatus(user)
    }

    fetchdata()
  }, [userStatus])

  if (userStatus) {
    navigate('/hr')
  } else {
  }

  return (
    <div className="bg-white">
      <form
        onSubmit={handleSubmit}
        className="max-w-xs md:max-w-xl mx-auto shadow-md mt-56 bg-zinc-200 rounded"
      >
        <div className="pt-5">
          <label className="px-5 text-3xl font-medium">Log in</label>
        </div>
        <div className="pt-10 pb-5 px-5">
          <label className="font-medium">user</label>
          <input
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            className="border-2 bg-white hover:border-black w-full p-2 rounded"
          ></input>
        </div>
        <div className="pb-3 px-5">
          <label className="font-medium">password</label>
          <input
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            type="password"
            className="border-2 bg-white hover:border-black w-full p-2 rounded"
          ></input>
        </div>
        <div className="flex justify-center md:justify-end p-5">
          <button className="border-2 border-green-600 text-white p-2 bg-green-600 rounded hover:brightness-75 w-full">
            log in
          </button>
        </div>
      </form>
    </div>
  )
}

export default LogIn
