import React, { useEffect, useState } from 'react'
import { supabase } from '../../../utils/supabaseCilent'
import { useNavigate } from 'react-router-dom'
import HrNavbar from '../../../component/Hr/HrNavbar'

function Dashboard() {
  let navigate = useNavigate()
  const [userStatus, setUserStatus] = useState('')
  useEffect(() => {
    const fetchdata = async () => {
      const user = supabase.auth.user()
      setUserStatus(user)
    }

    fetchdata()
  }, [userStatus])

  if (!userStatus) {
    navigate('/login')
  }

  return (
    <div className="bg-white">
      <HrNavbar />
      <div className="text-black font-normal text-4xl m-5 pt-5 max-w-7xl mx-auto">
        Dashboard
      </div>
    </div>
  )
}

export default Dashboard
